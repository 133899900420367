import * as React from 'react'
import { graphql } from 'gatsby'

import Header from '../components/Header/Header'
import TopBanner from '../components/Banners/TopBanner/TopBanner'
import Footer from '../components/Footer/Footer'
import Intro from '../components/organisms/intro/Intro'
import Blocks from '../components/Blocks/Blocks'
import PageMeta from '../components/PageMeta/PageMeta'
import { type IReaderTreatQueryData } from '../graphql/queries/ReaderTreatsQuery'
import { fixAmpersands } from '../lib/utils'
import Leaderboard from '../components/Banners/Leaderboard/Leaderboard'

export const query = graphql`
  query {
    wpgraphql {
      nationalReaderTreats {
        id
        title
        uri
        homepagePreviewImage
      }
      adStarCodes {
        id
        adslot
      }
      generalSettings {
        title
        siteId
        egSettingTwitter
        url
      }
    }
  }
`

interface ReaderTreatsProps {
  data: IReaderTreatQueryData
}

const ReaderTreats = ({
  data: {
    wpgraphql: { nationalReaderTreats, generalSettings, adStarCodes }
  }
}: ReaderTreatsProps) => {
  const { title, siteId } = generalSettings

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: 'Reader Treats | Muddy Stilettos',
          description:
            'Anyone told you how special you are recently? Well, we think you’re fabulous, so here are a whole raft of brilliant treats on us plus giveaways, gifts and competitions to win.'
        }}
      />
      <Header
        siteId={generalSettings.siteId}
        siteName={generalSettings.title}
      />
      <TopBanner ads={adStarCodes} showMPUs pageType={`readertreats`} />
      <Leaderboard adSlot={'TopLB'} sticky />
      <Intro>
        {siteId && siteId > 1 && <h4>{fixAmpersands(title ?? '')}</h4>}
        <h1>Reader Treats</h1>
        <p>
          Anyone told you how special you are recently? Well, we think you’re
          fabulous, so here are a whole raft of brilliant treats on us plus
          giveaways, gifts and competitions to win.
        </p>
      </Intro>
      <Blocks mpu posts={nationalReaderTreats} maxResults={24} />
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default ReaderTreats
